import './App.css';
import CsvReader from "../components/CsvReader";
import TemplateReader from "../components/TemplateReader";
import { useState } from "react";
import Previewer from "../components/Previewer";
import TemplateParser from "../modules/TemplateParser.ts";
import {TemplatesDownloader} from "../components/TemplatesDownloader";

function App() {
    const [csvData, setCsvData] = useState({'records': [], 'headers': [], 'key': ''});
    const [template, setTemplate] = useState({'parser': new TemplateParser(""), 'filename': ''});

    return (
        <div className="App">
            <header>
                <h1>Autogenerador de archivos html</h1>
            </header>
            <div className="main App-header">
                <CsvReader setCsv={setCsvData} getCsv={csvData} />
                <TemplateReader getTemplate={template} setTemplate={setTemplate} />
                <TemplatesDownloader template={template} csvData={csvData} />
            </div>
            <Previewer csvData={csvData} template={template} />
        </div>
    );
}

export default App;
