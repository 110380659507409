import parse from "html-react-parser";

function PreviewerHelpMessage() {
    return (
        <h3>Aquí puedes ver el resultado de tus archivos una vez subidos</h3>
    );
}

function PreviewerScreen(props) {
    const preview = props.preview
    return (
        <div>
            {parse(preview)}
        </div>
    );
}

function Previewer(props) {
    function renderFirstTemplate() {
        if (props.csvData.length === 0) {
            return ""
        }
        return props.template.parser.render(props.csvData.records[0])
    }

    const preview = renderFirstTemplate()
    const dataIsEmpty = props.csvData.records.length === 0 || preview === ""

    return (
        <div className='Previewer'>
            {dataIsEmpty ? <PreviewerHelpMessage/> : <PreviewerScreen preview={preview}/>}
        </div>
    );
}

export default Previewer;