import TemplateParser from "../modules/TemplateParser.ts";

function TemplateReader(props) {
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = e.target.result;
            const parser = new TemplateParser(fileContent);
            props.setTemplate({'parser': parser, 'filename': file.name});
        };
        reader.readAsText(file);
    };

    return (
        <div className="template">
            <h3>Plantilla</h3>
            <p>Sube aquí la plantilla base</p>
            <input type="file" className="file_button" onChange={handleFileUpload} />
            <pre>{props.getTemplate["parser"].fields.join(" ")}</pre>
        </div>
    );
}

export default TemplateReader;