import JSZip from "jszip";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";

export function TemplatesDownloader(props) {
    const dataIsAvailable = props.csvData.records.length > 0 && props.template.filename !== ''

    return (
        <div className="downloader">
            <h3>Descarga todos los resultados</h3>
            <button onClick={() => downloadZipFile(props.csvData, props.template)} disabled={!dataIsAvailable}>
                Descargar
            </button>
            <h3>¡Agradecemos tu contribución!</h3>
            <PayWithPaypalButton quantity={2}/>
        </div>
    )
}

function PayWithPaypalButton(props) {
    const YOUR_CLIENT_ID = "AZGXss9q-Gb6Y--t1tYSBIUTOY9hYnuq4VPATlj4qIGYk215Ubys5f2VlzTK46mzuIdiCNrFSfI2FPvO";
    const quantity = props.quantity;

    return (
        <PayPalScriptProvider options={{"client-id": YOUR_CLIENT_ID, "currency": "EUR"}}>
            <PayPalButtons
                style={{shape: 'rect', color: 'white', layout: 'horizontal', label: 'donate', tagline: false}}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{"amount": {"currency_code": "EUR", "value": quantity}}]
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then(function () {
                        alert('¡Gracias por tu contribución!');
                    });
                }}
                forceReRender={[quantity]}
                onError={(err) => {
                    console.log(err);
                }}
            />
        </PayPalScriptProvider>
    );
}

export function downloadZipFile(csvData, template, maxFiles = -1) {
    const zip = new JSZip()

    const records = (maxFiles === -1) ? csvData.records : csvData.records.slice(0, maxFiles)

    records.forEach((record, _) => {
        const fileName = `${record[csvData.key]}+${template.filename}`
        const fileContent = template.parser.render(record)
        zip.file(fileName, fileContent)
    })

    zip.generateAsync({type: "blob"}).then(function (content) {
        const file = new Blob([content], {type: "application/zip"})
        const link = document.createElement("a")
        link.href = URL.createObjectURL(file)
        link.download = "templates.zip"
        link.click()
    })
}
