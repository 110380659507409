import CsvParser from "../modules/CsvParser.ts";

function CsvReader(props) {
    const KEY_COLUMN = 'profile'

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const csvParser = new CsvParser(e.target.result, KEY_COLUMN);
                const records = csvParser.records;
                const headers = csvParser.headers;

                props.setCsv({'records': records, 'headers': headers, 'key': KEY_COLUMN});
            } catch (error) {
                const repeated_values = Array.from(error.values.values()).join('\n');
                alert(`Todos los valores de la columna "${KEY_COLUMN}" deben ser únicos. Revisar:\n${repeated_values}`);
            }
        };
        reader.readAsText(file);
    };

    return (
        <div className="bbdd">
            <h3>¿Qué datos utilizamos?</h3>
            <p>Sube aquí tu base de datos en formato csv</p>
            <input type="file" className="file_button" onChange={handleFileUpload}/>
            <pre>{props.getCsv.headers.join(" ")}</pre>
        </div>
    );

}

export default CsvReader;