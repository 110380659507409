const nunjucks = require('nunjucks')

class TemplateParser {
    private compiledTemplate = nunjucks.compile('')
    public readonly fields = []

    private static readonly field_regexp = /{{.+?}}/g;

    constructor(template: string) {
        this.compiledTemplate = nunjucks.compile(template)

        const matches = template.match(TemplateParser.field_regexp);
        this.fields = [];
        if (matches) {
            this.fields = matches.map(match => match.replace(/{{|}}/g, '')).map(field => field.trim());
        }
    }

    render(data: Record<string, string>): string {
        return this.compiledTemplate.render(data)
    }
}

export default TemplateParser;