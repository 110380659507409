import Papa from 'papaparse';

export class KeyNotUniqueError extends Error {
    key: string;
    values: Set<string>;

    constructor(key: string, values: Set<string>) {
        super(`Key ${key} has not unique values: ${Array.from(values.values()).join(",")}`);
        this.key = key;
        this.values = values;
    }
}

class CsvParser {
    public records = []
    public headers: string[] = []
    public key: string = ''

    constructor(csv: string, key: string) {
        const data = Papa.parse(csv, {
            header: true,
            skipEmptyLines: true
        })

        this.headers = data.meta.fields
        this.records = data.data
        this.key = key

        const keyValues = this.records.map(record => record[this.key]);
        const repeatedKeyValues = keyValues.filter(keyValue => keyValues.indexOf(keyValue) !== keyValues.lastIndexOf(keyValue));
        if (repeatedKeyValues.length > 0) {
            throw new KeyNotUniqueError(key, new Set(repeatedKeyValues));
        }
    }
}

export default CsvParser;